import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Section from "../components/Section";

export const query = graphql`
  query PageQuery($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      strapiId
      title
      visible
      peopleOnlineWidget
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
          url
        }
      }
      form {
        title
        spreadsheetId
        ctaText
        success
        error
        nameSurname {
          text
          placeholder
          required
          display
        }
        phone {
          text
          placeholder
          required
          display
        }
        email {
          text
          placeholder
          required
          display
        }
        recommendationNameSurname {
          text
          placeholder
          required
          display
        }
        recommendationPhone {
          text
          placeholder
          required
          display
        }
        additionalInfo {
          text
          placeholder
          required
          display
        }
        discountCode {
          text
          placeholder
          required
          display
        }
        agreement {
          text
          required
        }
        thankYouPagePath
      }
      sections
    }
  }
`;

const Page = ({ data }) => {
  const page = data.strapiPage;

  return (
    <Layout
      seo={page.seo}
      noindex={!page.visible}
      peopleOnlineWidget={page.peopleOnlineWidget}
    >
      {page.sections.map((section) => (
        <Section key={section.id} section={section} form={page.form} />
      ))}
    </Layout>
  );
};

export default Page;
